export function starPhone(phoneNum){
	// console.log(phoneNum)
	if(phoneNum.length==11){
		let str =  String(phoneNum),
		strLen = str.slice(-7,-3);
		let over =str.replace(strLen,"****")
		return over;
	}else{
		return phoneNum;
	}
}
export function overPhone(phoneNum,original){
	
		let str =  String(phoneNum)
		let originalStr =  String(original)
		let strLen = str.slice(-7,-3);
		original = originalStr.slice(-7,-3)
		let over =originalStr.replace(strLen,original)
		// console.log(over)
		return over;
	
}
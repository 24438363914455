import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
		token:'',
		user_type:0	
  },
  mutations: {
		 SetUser_type(state,user_type){
		            state.user_type = user_type
		        }
  },
  actions: {
  },
  modules: {
  }
})

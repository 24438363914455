<template>
  <div id="app">
   
    <router-view/>
  </div>
</template>
<script>
	import {getSign} from '@/api'
	 	export default {
	   data(){
	 	  return{
	 	  }
	   },
	   beforeCreate() {
		 //   console.log('sss')
			
			
	   },
	   beforeMount() {
		   
			// if(localStorage.getItem('user_id')=='MzIw'){
			// 		setTimeout(()=>{
			// 				// let url = window.location.href.split('?')[0]
			// 				  getSign({ page_url: location.href  })
			// 				    .then(res => {
			// 				      if (res.data.appId) {
			// 				        this.$wx.config({
			// 				          debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
			// 				          appId: res.data.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
			// 				          timestamp: res.data.timestamp, // 必填，生成签名的时间戳
			// 				          nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
			// 				          signature: res.data.signature, // 必填，签名，见附录1
			// 				  		jsApiList: ['scanQRCode',
			// 				  					'updateTimelineShareData',
			// 				  					'updateAppMessageShareData',
			// 				  					'onMenuShareAppMessage',
			// 				  					'onMenuShareTimeline'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
			// 				        });
			// 				      }
			// 				})
			// 		},3000)
				
			// 	}
	   },
	   methods:{
	   },
	   mounted() {
			// this.user()
			Date.prototype.Format = function (fmt) { //author: meizz 
				var o = {
					"M+": this.getMonth() + 1, //月份 
					"d+": this.getDate(), //日 
					"h+": this.getHours(), //小时 
					"m+": this.getMinutes(), //分 
					"s+": this.getSeconds(), //秒 
					"q+": Math.floor((this.getMonth() + 3) / 3), //季度 
					"S": this.getMilliseconds() //毫秒 
				};
				if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
				for (var k in o)
					if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
				return fmt;
			};
	   }
	 }
</script>
<style>
@import url("./assets/css/Eliminate.css");

#app {
    font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  letter-spacing:1px;
}
	[class*=van-hairline]::after{
			border: 0 !important;
		}
.van-cell:not(:last-child)::after{
		/* border-bottom: 1px solid #E5E5E5 !important; */
		border-bottom: none !important;
	}
	.van-image__error, .van-image__loading{
		top: 0 !important;
	}
.van-tabs--line .van-tabs__wrap{
	background-color: #fff;
}
.van-tabs__wrap{
	padding: 0 !important;
}
textarea.van-field__control{
			min-height:26px !important;
		}
	.list {
		flex-wrap: inherit;
	
		.list_img {
			margin-right: 0.71875rem;
			.icon{
				position: absolute;
				top: 10px;
				left: -3px;
				z-index: 2;
			}
		}
	
		.list_title {
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			font-weight: bold;
			font-size: 13px;
			color: #28334A;
		}
	
		.text {
			font-size: 0.75rem;
			color: #767e8d;
			margin-left: 0.5rem;
		}
	
		.btnimg {
			width: 3.3125rem;
			height: 1.625rem;
			text-align: center;
			line-height: 1.625rem;
			background: no-repeat center;
			background-size: 100% 100%;
			font-size: 0.6875rem;
			color: #FEFEFE;
		}
	
		.btnimg1 {
			background-image: url(/assets/Train/label_blue@2x.png);
		}
	
		.btnimg2 {
			background-image: url(/assets/Train/label_red@2x.png);
		}
	
		.btnimg3 {
			background-image: url(/assets/Train/label_gray@2x.png);
			color: #919CB0;
		}
	
		.btnimg_span {
			margin-right: -5px;
	
		}
	}

</style>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import imageClipper from "@/components/imageClipperIndex.js";
import { user,getSign} from "@/api";
// 引入裁剪图片组件
import H5Cropper  from 'vue-cropper-h5'
import  Base64  from 'base64-js';
Vue.prototype.$Base64 = Base64;
Vue.use(H5Cropper);
Vue.use(imageClipper);
Vue.prototype.$axios = axios;
axios.defaults.timeout = 6000000; // 请求超时
axios.defaults.baseURL = ""; // api 即上面 vue.config.js 中配置的地址
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)
import VueQuillEditor from "vue-quill-editor";
/**
 *引入手机号加密处理函数 和解密处理
 *
 * */
import {starPhone,overPhone} from '@/util/phoneNum.js'

Vue.prototype.$starPhone = starPhone;
Vue.prototype.$overPhone = overPhone;
// require styles
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

Vue.use(VueQuillEditor, {
  placeholder: "请输入内容"
});
import $ from "jquery";

import echarts from "echarts";
Vue.prototype.$echarts = echarts;
Vue.use(VueAxios, axios);
import { Skeleton } from "vant";
import { PullRefresh } from "vant";
import { Dialog } from "vant";
import wx from 'weixin-js-sdk'
Vue.prototype.$wx = wx
Vue.prototype.$Dialog = Dialog
Vue.use(PullRefresh);
Vue.use(Skeleton);
import { AddressEdit } from 'vant';

Vue.use(AddressEdit);
import { ActionSheet } from 'vant';
 
Vue.use(ActionSheet);
import {
	Stepper ,
  Image,
  Tag,
  Row,
  Col,
  Cell,
  CellGroup,
  Lazyload,
  Icon,
  Divider,
  ImagePreview,
  Uploader,
  Button,
  Swipe,
  SwipeItem,
  List,
  Overlay,
  Area,
  Popup,
  Form
} from "vant";
import { RadioGroup, Radio, Calendar, DatetimePicker } from "vant";
import { Tab, Tabs, Picker, Toast, Field, Checkbox, CheckboxGroup, Loading } from "vant";
import { Rate } from "vant";

Vue.prototype.$Toast = Toast;
import { PasswordInput, NumberKeyboard ,Card} from "vant";
import { Step, Steps } from 'vant';

Vue.use(Step);
Vue.use(Steps);
Vue.use(Card);
Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
Vue.use(Rate);
Vue.use(Stepper);
Vue.use(Checkbox).use(CheckboxGroup);
Vue.use(Field);
Vue.use(DatetimePicker);
Vue.use(Picker).use(Toast);
Vue.use(Loading);
Vue.use(Calendar); 
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Tab).use(Tabs);
// 按需引入组件
Vue.use(Area).use(Popup);
Vue.use(Overlay);
Vue.use(List);
Vue.use(Swipe).use(SwipeItem);
Vue.use(Divider);
Vue.use(Icon);
Vue.use(ImagePreview);
// options 为可选参数，无则不传
Vue.use(Lazyload, {
  lazyComponent: true
});
Vue.use(Uploader);
Vue.use(Cell).use(CellGroup);
Vue.use(Row).use(Col);
Vue.use(Button);
Vue.use(Tag);
Vue.use(Image);
Vue.use(Form);
Vue.config.productionTip = false;
import "vant/lib/index.css";

import vueCanvasPoster from 'vue-canvas-poster';
Vue.use(vueCanvasPoster)

function getQueryString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
  var r = window.location.search.substr(1).match(reg); //匹配目标参数
  if (r != null) return decodeURI(r[2]);
  return null;
}
		let _this=this
		
			
let baseLocation = 'https://xcjj.tysos.cn'

router.beforeEach((to, from, next) => {
	
	 if (window.__wxjs_is_wkwebview) { // IOS
	    if (window.entryUrl == '' || window.entryUrl == undefined) {
	      var url = baseLocation  + to.fullPath
	      window.entryUrl = url
		  // console.log('IOS', window.entryUrl)
	    } else {
	      // console.log('IOS2', window.entryUrl)
	    }
	  } else { // 安卓+
	  var url = baseLocation  + to.fullPath
	    window.entryUrl = url
	  }
	
	if(localStorage.getItem('user_id')=='MzIw'){
		
		wx.ready(res => {  // 微信SDK准备就绪后执行的回调。
			wx.onMenuShareAppMessage({
				title: '现场急救行动指南', // 分享标题
				desc: '现场急救行动指南', // 分享描述
				link: 'https://xcjj.tysos.cn'+to.fullPath, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
				imgUrl: 'https://xcjj.tysos.cn/gzh/backend/public/uploads/user_photo/2020-06/1593499437jhofvxhz.png', // 分享图标
				type: 'link', // 分享类型,music、video或link，不填默认为link
				dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
				success: function () {
				// 用户点击了分享后执行的回调函数
				}
			});
			wx.updateAppMessageShareData({
				title: '现场急救行动指南', // 分享标题
				desc: '现场急救行动指南', // 分享描述
				link: 'https://xcjj.tysos.cn'+to.fullPath, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
				imgUrl: 'https://xcjj.tysos.cn/gzh/backend/public/uploads/user_photo/2020-06/1593499437jhofvxhz.png', // 分享图标
				success: function(resq) {
					clearInterval(timeVal)
					// 设置成功
				},
				error: function(resw) {
					// alert('')
				}
			})
			// 分享朋友圈
			wx.updateTimelineShareData({
				title: '现场急救行动指南', // 分享标题
				link: 'https://xcjj.tysos.cn'+to.fullPath, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
				imgUrl: 'https://xcjj.tysos.cn/gzh/backend/public/uploads/user_photo/2020-06/1593499437jhofvxhz.png', // 分享图标
				success: function() {
					// 设置成功
				}
			});
		})
	}
	// ---------------------------
	// window.addEventListener( 'beforeunload', e => {
	// 	if(localStorage.getItem('token')){
	// 		localStorage.removeItem('token')
	// 	}
	// });
	
	// -----------------
	// if(from.fullPath=='/'  && to.fullPath.indexOf('token=') == -1 &&to.fullPath.indexOf('t=') == -1 ){
	// 			sessionStorage.removeItem('token')
	// }
	
  if (to.query.token) {
	sessionStorage.setItem('token',to.query.token)
    // localStorage.setItem("token", to.query.token);
	let a = to.fullPath.replace('oken=','=')
    next({path:a});
	
  } else {
    next();
  }
  

  user().then(res => {
    localStorage.setItem("user_data", JSON.stringify(res.data));
    localStorage.setItem("user_type", res.data.user_type);
    localStorage.setItem("user", res.data.user_name);
	let Base64 = require('js-base64').Base64
	let user_id=Base64.encode((res.data.user_id).toString())
	localStorage.setItem('user_id',user_id)
	store.commit('SetUser_type',res.data.user_type)
	/**
	 * 做伪分享功能
	 * 用户进来的时候判断参数用户id是否和登录的用户id是否一致
	 * 是的话继续
	 * 不是则跳购买书籍页面
	 * */ 
	if(to.name=="answer"||to.name=="chapter"||to.name=="test"){
		console.log(to.query)
		if(to.query.user_id!=user_id){
			// next({path:'/goodBookDetails'})
		}else{
			if(res.data.is_securitycode==0){
				next({name:'onlineClassroom'})
			}
		}
	}
	if(res.data.user_name==''){
		next({path:'/setMaterial',query:{type1:'修改真实姓名'}})
	}
	
	// 限制处理
  //   if (
		// res.data.user_id !== 15 &&
		// res.data.user_id !== 27 &&
		// res.data.user_id !== 31 &&
		// res.data.user_id !== 40 &&
		// res.data.user_id !== 210&&
		// res.data.user_id !== 14 &&
		// res.data.user_id !== 34 &&
		// res.data.user_id !== 29
  //   ) {
  //     next({
  //       path: "/404"
  //     });
  //   }
  });
  
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

import Vue from "vue";
import VueRouter from "vue-router";
// import Home from '../views/Home.vue'
// import store from '../store'
Vue.use(VueRouter);
let isgen = false;

const routes = [
  {
    // 项目介绍
    path: isgen ? "/home_gen" : "/home",
    name: "home",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Home/Home.vue")
  },
  {
    // 空白页
    path: "/404", 
    name: "404",
	props: { '_r': Math.random() } ,
    component: () => import(/* webpackChunkName: "about" */ "../views/404.vue")
  },
  {
    // 项目介绍详情
    path: "/About",
    name: "About",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About/About.vue")
  },
  {
    // 我的宝藏
  
    path: "/ScanCodeLogin",
    name: "ScanCodeLogin",
  	query:{time:new Date().getTime()},
  	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/user/ScanCodeLogin.vue")
  },
  {
    // 我的宝藏

    path: isgen ? "/personalUser_gen" : "/personalUser",
    name: "personalUser",
	query:{time:new Date().getTime()},
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/user/personalUser.vue")
  },
  {
    // 急救培训首页
    path: isgen ? "/train_index_gen" : "/train_index",
    name: "train_index",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Train/train_index.vue")
  },
  {
    // 导师榜单
    path: "/mentorList",
    name: "mentorList",
	props: { '_r': Math.random() } ,
	query:{'time':new Date().getTime()},
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Train/mentorList.vue")
  },
  {
    // 导师主页
    path: "/tutorHomepage",
    name: "tutorHomepage",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Train/tutorHomepage.vue")
  },
  {
    // 活动详情
    path: "/courseDetails",
    name: "courseDetails",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Train/courseDetails.vue")
  },
  {
    // 报名详情页面
    path: "/ApplicationDet",
    name: "ApplicationDet",
	props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Train/ApplicationDet.vue"
      )
  },
  {
    // 报名成功页面
    path: "/signSuccess",
    name: "signSuccess",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Train/signSuccess.vue")
  },
  {
    // 个人证书页面
    path: "/UserCertificate",
    name: "UserCertificate",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/user/UserCertificate.vue")
  },
  {
    // 个人资料页面
    path: "/user_pic_style",
    name: "user_pic_style",
  	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/user/user_pic_style.vue")
  },
  {
    // 修改个人资料页面 需带参数
    path: "/setMaterial",
    name: "setMaterial",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/user/setMaterial.vue")
  },
  {
    // 修改个人资料页面 需带参数
    path: "/setMaterial1",
    name: "setMaterial1",
  	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/user/setMaterial1.vue")
  },
  {
    // 发布培训页面
    path: "/release",
    name: "release",
	props: { '_r': Math.random() } ,
	query:{time:new Date().getTime()},
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/user/release.vue")
  },
  {
    // 私聊内容
    path: "/letter",
    name: "letter",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/letter/letter.vue")
  },
  {
    // 私聊列表
    path: "/letterList",
    name: "letterList",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/letter/letterList.vue")
  },
  {
    // 我的培训
    path: "/userTrain",
    name: "userTrain",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/UserTrain/userTrain.vue")
  },
  {
    // 我的培训-证书
    path: "/emUserCertificate",
    name: "emUserCertificate",
	props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/UserTrain/userCertificate.vue"
      )
  },
  {
    // 上传培训图片
    path: "/upTrainImage",
    name: "upTrainImage",
	  props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/UserTrain/upTrainImage.vue"
      )
  },
  // 确认证书信息
  {
    // 我的培训-证书
    path: "/confirmation_card",
    name: "confirmation_card",
  	props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/UserTrain/confirmation_card.vue"
      )
  },
  {
    // 我的培训——查看评论
    path: "/userTrainEvaluate_i",
    name: "userTrainEvaluate_i",
	props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/UserTrain/userTrainEvaluate_i.vue"
      )
  },
  {
    // 培训详情和管理
    path: "/traindal",
    name: "traindal",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/UserTrain/traindal.vue")
  },
  {
    // 我的导师班培训
    path: "/tutorTrain",
    name: "tutorTrain",
	props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/userTutor/tutorTrain.vue"
      )
  },

  {
    // 我的导师班培训-证书
    path: "/tutorCertificate",
    name: "tutorCertificate",
	props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/userTutor/tutorCertificate.vue"
      )
  },
  {
    // 培训详情和管理
    path: "/tutortraindal",
    name: "tutortraindal",
	props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/userTutor/tutortraindal.vue"
      )
  },
  {
    // 我报名的培训
    path: "/signUpTrain",
    name: "signUpTrain",
	props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/signUpTrain/signUpTrain.vue"
      )
  },
  {
    // 我报名的培训——取消报名
    path: "/cancel",
    name: "cancel",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/signUpTrain/cancel.vue")
  },
  {
    // 我报名的培训——发表评论
    path: "/evaluate",
    name: "evaluate",
	props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/signUpTrain/evaluate.vue"
      )
  },
  {
    // 我报名的培训——查看评论
    path: "/evaluate_i",
    name: "evaluate_i",
	props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/signUpTrain/evaluate_i.vue"
      )
  },
  {
    // 我报名的培训——培训证书
    path: "/certificateDet",
    name: "certificateDet",
	props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/signUpTrain/certificateDet.vue"
      )
  },
  {
    // 我报名的培训——考核记录
    path: "/Assessment",
    name: "Assessment",
	props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/signUpTrain/Assessment.vue"
      )
  },
  {
    // 督导预约
    path: "/supervisor",
    name: "supervisor",
	props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/supervisor/supervisor.vue"
      )
  },
  {
    // 督导预约
    path: "/super_traindal",
    name: "super_traindal",
	props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/supervisor/super_traindal.vue"
      )
  },
  {
    // 我的关注
    path: "/follow",
    name: "follow",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/user/follow.vue")
  },

  {
    // 培训统计导师
    path: "/statistics",
    name: "statistics",
	props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/statistics/statistics.vue"
      )
  },
  {
    // 培训统计主任导师
    path: "/statisticsTotor",
    name: "statisticsTotor",
	props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/statistics/statisticsTotor.vue"
      )
  },
  {
    // 我的钱包
    path: "/wallet",
    name: "wallet",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/wallet/wallet.vue")
  },
  {
    // 充值
    path: "/recharge",
    name: "recharge",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/wallet/recharge.vue")
  },
  {
    // 充值成功
    path: "/recharge_ok",
    name: "recharge_ok",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/wallet/recharge_ok.vue")
  },
  {
    // 使用记录
    path: "/UsageRecord",
    name: "UsageRecord",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/wallet/UsageRecord.vue")
  },
  {
    // 设置支付密码
    path: "/setPasswrod",
    name: "setPasswrod",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/wallet/setPasswrod.vue")
  },
	{
	  // 重置支付密码
	  path: "/setPasswrod1",
	  name: "setPasswrod1",
	props: { '_r': Math.random() } ,
	  component: () =>
	    import(/* webpackChunkName: "about" */ "../views/wallet/setPasswrod1.vue")
	},
  {
    // 培训证书
    path: "/certificate_train",
    name: "certificate_train",
	props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/certificate/certificate_train.vue"
      )
  },
  {
    // 培训证书
    path: "/certificateDet_user",
    name: "certificateDet_user",
  	props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/certificate/certificateDet_user.vue"
      )
  },
  {
    // 津贴记录
    path: "/allowance",
    name: "allowance",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/wallet/allowance.vue")
  },
  {
    // 我的学员
    path: "/myTrainee",
    name: "myTrainee",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/myTrainee/myTrainee.vue")
  },
  {
    // 我的证书
    path: "/certificate_vid",
    name: "certificate_vid",
	props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/certificate/certificate_vid.vue"
      )
  },
  {
    // 急救大汇
    path: isgen ? "/bigSink_gen" : "/bigSink",
    name: "bigSink",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/bigSink/bigSink.vue")
  },
  {
    // 演练场
    path: "/ylc_list",
    name: "ylc_list",
	props: { '_r': Math.random() } ,
    component: () =>
		import(/* webpackChunkName: "about" */ "../views/bigSink/ylc_list.vue")
  },
  {
    // 演练场详情
    path: "/lyc_details",
    name: "lyc_details",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/bigSink/lyc_details.vue")
  },
  {
    // 发布演练视频
    path: "/ylc_release",
    name: "ylc_release",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/bigSink/ylc_release.vue")
  },
	
	// 急救视频
	{
	  // 急救视频
	  path: "/FirstAidVideo_list",
	  name: "FirstAidVideo_list",
	  props: { '_r': Math.random() } ,
	  component: () =>
	    import(/* webpackChunkName: "about" */ "../views/bigSink/FirstAidVideo_list.vue")
	},
	{
	  // 急救视频详情
	  path: "/FirstAidVideo_details",
	  name: "FirstAidVideo_details",
	  props: { '_r': Math.random() } ,
	  component: () =>
	    import(/* webpackChunkName: "about" */ "../views/bigSink/FirstAidVideo_details.vue")
	},
	{
	  // 发布急救视频
	  path: "/FirstAidVideo_release",
	  name: "FirstAidVideo_release",
	  props: { '_r': Math.random() } ,
	  component: () =>
	    import(/* webpackChunkName: "about" */ "../views/bigSink/FirstAidVideo_release.vue")
	},
  {
    // 急救热文
    path: "/hotText",
    name: "hotText",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/hotText/hotText.vue")
  },
  {
    // 查看急救热文
    path: "/viewArticles",
    name: "viewArticles",
	props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/hotText/viewArticles.vue"
      )
  },
  {
    // 急救热文消息
    path: "/news",
    name: "news",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/hotText/news.vue")
  },
  {
    // 急救热文-发布文章
    path: "/FBArticle",
    name: "FBArticle",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/hotText/FBArticle.vue")
  },
  {
    // 急救热文-发布文章
    path: "/FBArticle_xiu",
    name: "FBArticle_xiu",
	props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/hotText/FBArticle_xiu.vue"
      )
  },
  {
    // 急救热文-发布文章
    path: "/userSink",
    name: "userSink",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/hotText/userSink.vue")
  },
  {
    // 急救答疑
    path: "/questions",
    name: "questions",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/questions/questions.vue")
  },
  {
    // 急救答疑详情
    path: "/questionsDel",
    name: "questionsDel",
	props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/questions/questionsDel.vue"
      )
  },
  {
    // 急救答疑详情
    path: "/userQuestions",
    name: "userQuestions",
	props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/questions/userQuestions.vue"
      )
  },
  {
    // 线下课堂
    path: "/onlineClassroom_gen",
    name: "onlineClassroom",
	props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/onlineClassroom/onlineClassroom.vue"
      )
  },
  {
    // 线下课堂-章节答题列表页面
    path: "/chapter_gen",
    name: "chapter",
	props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/onlineClassroom/chapter.vue"
      )
  },
  {
    // 线下课堂-章节答题列表页面
    path: "/chapter_xu_gen",
    name: "chapter_xu",
  	props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/onlineClassroom/chapter_xu.vue"
      )
  },
  {
    // 线下课堂-篇章列表
    path: "/answer_gen",
    name: "answer",
	props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/onlineClassroom/answer.vue"
      )
  },
  {
    // 线下课堂-答题页面
    path: "/test_gen",
    name: "test",
	props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/onlineClassroom/test.vue"
      )
  },

  {
    // 知识更新
    path: "/toUpdate",
    name: "toUpdate",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/toUpdate/toUpdate.vue")
  },
	{
	  // 知识更新详情
	  path: "/toUpdateDel",
	  name: "toUpdateDel",
	  props: { '_r': Math.random() } ,
	  component: () =>
	    import(/* webpackChunkName: "about" */ "../views/toUpdate/toUpdateDel.vue")
	},
  {
    // 修改头像
    path: "/setHead",
    name: "setHead",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/user/setHead.vue")
  },
  {
    // 修改证书头像
    path: "/certificateHead",
    name: "certificateHead",
  	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/user/certificateHead.vue")
  },
  {
    // 导师申请
    path: "/apply",
    name: "apply",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Train/apply.vue")
  },
  {
    // 我的培训-编辑
    path: "/setTrain",
    name: "setTrain",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/UserTrain/setTrain.vue")
  },
  {
    // 我的培训-发布反馈页面
    path: "/feedbackFrom",
    name: "feedbackFrom",
	props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/UserTrain/feedbackFrom.vue"
      )
  },

  {
    // 签到页面
    path: "/SignInFrom",
    name: "SignInFrom",
	props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/SignInFrom/SignInFrom.vue"
      )
  },
  {
    // 查看反馈
    path: "/lookFeedbackFrom",
    name: "lookFeedbackFrom",
	props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Train/lookFeedbackFrom.vue"
      )
  },
  {
    // 通知页面-督导预约接收拒绝
    path: "/supervisionNotice",
    name: "supervisionNotice",
	props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/notice/supervisionNotice.vue"
      )
  },
	{
	  // 通知页面-督导预约付费
	  path: "/supervisionNoticePay",
	  name: "supervisionNoticePay",
	  props: { '_r': Math.random() } ,
	  component: () =>
	    import(
	      /* webpackChunkName: "about" */ "../views/notice/supervisionNoticePay.vue"
	    )
	},
  {
    // 通知页面-助教导师替换
    path: "/assistantReplace",
    name: "assistantReplace",
	props: { '_r': Math.random() } ,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/notice/assistantReplace.vue"
      )
  },
  {
    // 预览
    path: "/preview",
    name: "preview",
	props: { '_r': Math.random() } ,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/hotText/preview.vue")
  },
	{
	  // 好书推荐
	  path: "/GoodBook",
	  name: "GoodBook",
	  props: { '_r': Math.random() } ,
	  component: () =>
	    import(/* webpackChunkName: "about" */ "../views/recommendation/GoodBook.vue")
	},
	{
	  // 购买成功书籍
	  path: "/purSuccess",
	  name: "purSuccess",
	  props: { '_r': Math.random() } ,
	  component: () =>
	    import(/* webpackChunkName: "about" */ "../views/recommendation/purSuccess.vue")
	},
	{
	  // 好书推荐详情
	  path: "/goodBookDetails",
	  name: "goodBookDetails",
	  props: { '_r': Math.random() } ,
	  component: () =>
	    import(/* webpackChunkName: "about" */ "../views/recommendation/goodBookDetails.vue")
	},
	{
	  // 合作意向报名
	  path: "/cooperationJoin",
	  name: "cooperationJoin",
	  props: { '_r': Math.random() } ,
	  component: () =>
	    import(/* webpackChunkName: "about" */ "../views/Train/cooperationJoin.vue")
	},
	{
	  // 好书推荐订单
	  path: "/goodBookOrder",
	  name: "goodBookOrder",
	  props: { '_r': Math.random() } ,
	  component: () =>
	    import(/* webpackChunkName: "about" */ "../views/recommendation/goodBookOrder.vue")
	},
	
	{
	  // 快递
	  path: "/deliveryByOrderid",
	  name: "deliveryByOrderid",
	  props: { '_r': Math.random() } ,
	  component: () =>
	    import(/* webpackChunkName: "about" */ "../views/deliveryByOrderid/deliveryByOrderid.vue") 
	},
	
	{
	  // 考核
	  path: "/inAassessment",
	  name: "inAassessment",
	  props: { '_r': Math.random() } ,
	  component: () =>
	    import(/* webpackChunkName: "about" */ "../views/UserTrain/inAassessment.vue") 
	},
	{
	  // 考核中
	  path: "/InTheAssessment",
	  name: "InTheAssessment",
	  props: { '_r': Math.random() } ,
	  component: () =>
	    import(/* webpackChunkName: "about" */ "../views/UserTrain/InTheAssessment.vue") 
	},
	{
	  // 考核中完成，查看成绩
	  path: "/InTheAssessmentOK",
	  name: "InTheAssessmentOK",
	  props: { '_r': Math.random() } ,
	  component: () =>
	    import(/* webpackChunkName: "about" */ "../views/UserTrain/InTheAssessmentOK.vue") 
	},
	
	/*
	* 导师班和督导考核
	*/
	{
	  // 考核
	  path: "/tutorInAassessment",
	  name: "tutorInAassessment",
	  props: { '_r': Math.random() } ,
	  component: () =>
	    import(/* webpackChunkName: "about" */ "../views/userTutor/tutorInAassessment.vue") 
	},
	{
	  // 考核中
	  path: "/tutorInTheAssessment",
	  name: "tutorInTheAssessment",
	  props: { '_r': Math.random() } ,
	  component: () =>
	    import(/* webpackChunkName: "about" */ "../views/userTutor/tutorInTheAssessment.vue") 
	},
	{
	  // 考核中完成，查看成绩
	  path: "/tutorInTheAssessmentOK",
	  name: "tutorInTheAssessmentOK",
	  props: { '_r': Math.random() } ,
	  component: () =>
	    import(/* webpackChunkName: "about" */ "../views/userTutor/tutorInTheAssessmentOK.vue") 
	},
	{
	  // 考核中
	  path: "/supervisorTheAssessment",
	  name: "supervisorTheAssessment",
	  props: { '_r': Math.random() } ,
	  component: () =>
	    import(/* webpackChunkName: "about" */ "../views/supervisor/supervisorTheAssessment.vue") 
	},
	{
	  // 考核中完成，查看成绩
	  path: "/supervisorTheAssessmentOK",
	  name: "supervisorTheAssessmentOK",
	  props: { '_r': Math.random() } ,
	  component: () =>
	    import(/* webpackChunkName: "about" */ "../views/supervisor/supervisorTheAssessmentOK.vue") 
	},
	{
	  // 已加入的发证机构
	  path: "/mechanism",
	  name: "mechanism",
	  props: { '_r': Math.random() } ,
	  component: () =>
	    import(/* webpackChunkName: "about" */ "../views/mechanism/mechanism.vue") 
	},
	{
	  // 已加入的发证机构
	  path: "/demonstration",
	  name: "demonstration",
	  props: { '_r': Math.random() } ,
	  component: () =>
	    import(/* webpackChunkName: "about" */ "../views/onlineClassroom/demonstration.vue") 
	},
	{
	  // 培训机构详情
	  path: "/cooperativeList",
	  name: "cooperativeList",
	  props: { '_r': Math.random() } ,
	  component: () =>
	    import(/* webpackChunkName: "about" */ "../views/trainMechanism/cooperativeList.vue") 
	},

	{
	  // 培训机构详情
	  path: "/mechanismDetails",
	  name: "mechanismDetails",
	  props: { '_r': Math.random() } ,
	  component: () =>
	    import(/* webpackChunkName: "about" */ "../views/trainMechanism/mechanismDetails.vue") 
	},

  {
	  // 培训邀约
	  path: "/trainInvitation",
	  name: "trainInvitation",
	  props: { '_r': Math.random() } ,
	  component: () =>
	    import(/* webpackChunkName: "about" */ "../views/trainMechanism/trainInvitation.vue") 
	},

  {
	  // 我要咨询
	  path: "/toConsultingService",
	  name: "toConsultingService",
	  props: { '_r': Math.random() } ,
	  component: () =>
	    import(/* webpackChunkName: "about" */ "../views/trainMechanism/toConsultingService.vue") 
	},
  {
	  // 咨询列表
	  path: "/consultingServiceList",
	  name: "consultingServiceList",
	  props: { '_r': Math.random() } ,
	  component: () =>
	    import(/* webpackChunkName: "about" */ "../views/trainMechanism/consultingServiceList.vue") 
	},

  {
	  // 活动审核
	  path: "/activityAudit",
	  name: "activityAudit",
	  props: { '_r': Math.random() } ,
	  component: () =>
	    import(/* webpackChunkName: "about" */ "../views/Train/activityAudit.vue") 
	},
  {
	  // 助教分配/学员分组
	  path: "/distributionAssistant",
	  name: "distributionAssistant",
	  props: { '_r': Math.random() } ,
	  component: () =>
	    import(/* webpackChunkName: "about" */ "../views/UserTrain/distributionAssistant") 
	},
]; 

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  // base: '/gzh/web/',
  routes
});

console.log(process.env.BASE_URL);
export default router;

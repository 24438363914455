import service from './service'
import CommonInterface from './CommonInterface'

// 上传图片
export const postFeedbackImg = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/postFeedbackImg', params);
/**
 * 其他
 * */
// 获取省份
export const getProvinces = (params = {}) => service.get('/gzh/backend/public/index.php/api/common/getProvinces', params);

// 获取城市
export const getCities = (params = {}) => service.get('/gzh/backend/public/index.php/api/common/getCities', params);

// 获取区域
export const getAreas = (params = {}) => service.get('/gzh/backend/public/index.php/api/common/getAreas', params);

/*-------------------------------------*/
/* 个人资料 */

// 获得个人资料
export const user = (params = {}) => service.get('/gzh/backend/public/index.php/api/user/index', params);
// 设置登录密码
export const postClientUserscode = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/postClientUserscode', params);

// 修改头像
export const postUserHeadImg = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/postUserHeadImg', params);
export const postTransformationImg = (params = {}) => service.post('/gzh/backend/public/index.php/api/activity/postUserHeadImg', params);

// 修改个人资料
export const postUserInfo = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/postUserInfo', params);

// 修改手机号码
export const postSetUserPhone = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/postSetUserPhone', params);

// 修改验证码
export const getMobileCode = (params = {}) => service.get('/gzh/backend/public/index.php/api/user/getMobileCode', params);

// 添加/修改导师资质
export const postQualifications = (params = {}) => service.get('/gzh/backend/public/index.php/api/user/postQualifications', params);
// 私信列表
export const getLetterLists = (params = {}) => service.get('/gzh/backend/public/index.php/api/privateletter/getLetterLists', params);

// 私信内容
export const getContentLists = (params = {}) => service.get('/gzh/backend/public/index.php/api/privateletter/getContentLists', params);

// 私信变更为已读/全部已读
export const postLetterReadStatus = (params = {}) => service.post('/gzh/backend/public/index.php/api/privateletter/postLetterReadStatus', params);
// 发送私信
export const postLetter = (params = {}) => service.post('/gzh/backend/public/index.php/api/privateletter/postLetter', params);

// // 我的培训（导师）
export const userGetActivityLists = (params = {}) => service.get('/gzh/backend/public/index.php/api/user/getActivityLists', params);
/*-------------------------------------*/

/* 急救培训 */
// 首页培训统计
export const getActivityDataCount = (params = {}) => CommonInterface.get('/gzh/backend/public/index.php/api/activity/getActivityDataCount', params);
// 轮播图
export const getBanner = (params = {}) => CommonInterface.get('/gzh/backend/public/index.php/api/other/getNewBanner', params);
// 活动报名
export const postSignup = (params = {}) => service.post('/gzh/backend/public/index.php/api/activity/postSignup', params);
// 活动地址
export const getActAddress = (params = {}) => service.post('/gzh/backend/public/index.php/api/activity/getActAddress', params);
// 导师榜单
export const getTutorLists = (params = {}) => service.get('/gzh/backend/public/index.php/api/tutor/getTutorLists', params);
// 导师详情
export const getInfo = (params = {}) => service.get('/gzh/backend/public/index.php/api/tutor/getInfo', params);
// 关注/取消关注
export const postFollow = (params = {}) => service.get('/gzh/backend/public/index.php/api/tutor/postFollow', params);
// 培训活动列表
export const getActivityLists = (params = {}) => service.get('/gzh/backend/public/index.php/api/activity/getActivityLists', params);
// 热门城市
export const gethotcity = (params = {}) => CommonInterface.get('/gzh/backend/public/index.php/api/other/gethotcity', params);
// 急救培训培训活动内容
export const getActivity_t = (params = {}) => CommonInterface.get('/gzh/backend/public/index.php/api/activity/getActivity', params);
// 我的培训-培训活动内容
export const getActivity = (params = {}) => service.get('/gzh/backend/public/index.php/api/user/getActivity', params);
//  我的培训-培训活动内容
export const getActivity_is = (params = {}) => service.get('/gzh/backend/public/index.php/api/activity/getActivity', params);
// 获取考核课程/培训课程
export const getOfficialCourse = (params = {}) => CommonInterface.get('/gzh/backend/public/index.php/api/activity/getOfficialCourse', params);
// 获取考核课程/培训课程
export const activityAuthCheck = (params = {}) => service.get('/gzh/backend/public/index.php/api/user/activityAuthCheck', params);
// 发证机构
export const getInstitutions = (params = {}) => service.get('/gzh/backend/public/index.php/api/other/getInstitutions', params);
// 发布培训活动
export const postActivity = (params = {}) => service.post('/gzh/backend/public/index.php/api/activity/postActivity', params);
// 编辑培训活动
export const editActivity = (params = {}) => service.post('/gzh/backend/public/index.php/api/activity/editActivity', params);

// 我报名的培训
export const getMySignupActivityLists = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/getMySignupActivityLists', params);

// 培训统计（导师）
export const getActivityDataCount_tutor = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/getActivityDataCount', params);
// 培训统计（导师）
export const getActivityDataCounts = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/getActivityDataCounts', params);
// 我的关注
export const myFollowing = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/myFollowing', params);

// 我的学员
export const getStudentLists = (params = {}) => service.get('/gzh/backend/public/index.php/api/user/getStudentLists', params);

// 查看评价
export const getActivityComment = (params = {}) => service.get('/gzh/backend/public/index.php/api/user/getActivityComment', params);

// 发表评价
export const postActivityComment = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/postActivityComment', params);

// 导师查看自己活动的评论
export const getActivityCommentLists = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/getActivityCommentLists', params);

// 我报名的培训-取消报名
export const postCancelSignup = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/postCancelSignup', params);

// 我的宝藏-我的证书
export const getMyOfficialCertsLists = (params = {}) => service.get('/gzh/backend/public/index.php/api/user/getMyOfficialCertsLists', params);
// 我的宝藏-我的证书详情
export const getMyOfficialCerts = (params = {}) => service.get('/gzh/backend/public/index.php/api/user/getMyOfficialCerts', params);
// 申请助教导师
export const postSignupTutor = (params = {}) => service.get('/gzh/backend/public/index.php/api/activity/postSignupTutor', params);
// 签到

export const postSignin = (params = {}) => service.get('/gzh/backend/public/index.php/api/activity/postSignin', params);
// 助教导师签到
export const postTutorSignin = (params = {}) => service.post('/gzh/backend/public/index.php/api/activity/postTutorSignin', params);
// 培训详情的学员报名数据
export const getActivitySignupLists = (params = {}) => service.get('/gzh/backend/public/index.php/api/user/getActivitySignupLists', params);
// 培训详情的助教导师报名列表
export const getActivitySignupTutorLists = (params = {}) => service.get('/gzh/backend/public/index.php/api/user/getActivitySignupTutorLists', params);
// 培训详情的学员签到列表
export const  getActivitySigninLists = (params = {}) => service.get('/gzh/backend/public/index.php/api/user/getActivitySigninLists', params);
// 培训详情的助教导师签到列表
export const  getActivitySigninTutorLists = (params = {}) => service.get('/gzh/backend/public/index.php/api/user/getActivitySigninTutorLists', params);
// 培训详情的证书发放列表
export const  getActivitySigninCertsLists = (params = {}) => service.get('/gzh/backend/public/index.php/api/user/getActivitySigninCertsLists', params);
// 培训详情考核课程证书
export const  getOfficialActivityCertListByUser = (params = {}) => service.get('/gzh/backend/public/index.php/api/user/getOfficialActivityCertListByUser', params);
// 改变活动状态
export const changeActivityStatus = (params = {}) => service.get('/gzh/backend/public/index.php/api/activity/changeActivityStatus', params);
// 培训审核详情
export const  getAuditingGetActivityDetail= (params = {}) => service.post('/gzh/backend/public/index.php/api/demo/auditingGetActivityDetail', params);
// 发起审核
export const changeAuditingGetActivityComplete = (params = {}) => service.post('/gzh/backend/public/index.php/api/demo/auditingGetActivityComplete', params);
// 助教列表
export const getActTeacherList = (params = {}) => service.post('/gzh/backend/public/index.php/api/Actmember/getActTeacherList', params);
// 分配学员给助教
export const distributionStudents = (params = {}) => service.post('/gzh/backend/public/index.php/api/Actmember/distributionStudents', params);

// 活动精彩回顾
export const getActivityFeedbackList = (params = {}) => service.get('/gzh/backend/public/index.php/api/user/getActivityFeedbackList', params);
// 导师发布活动反馈
export const postActivityFeedback = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/postActivityFeedback', params);
// 取消助教导师报名
export const getCancelSignupTutor = (params = {}) => service.get('/gzh/backend/public/index.php/api/activity/getCancelSignupTutor', params);
// 搜索替换助教导师发送短信
export const getSearchAndSendMessage = (params = {}) => service.get('/gzh/backend/public/index.php/api/activity/getSearchAndSendMessage', params);
// 助教导师替换
export const getChangeOfficialSignupTutor = (params = {}) => service.get('/gzh/backend/public/index.php/api/activity/getChangeOfficialSignupTutor', params);
// 助教导师替换邀请页面
export const getChangeInfoOfficialSignupTutor = (params = {}) => service.get('/gzh/backend/public/index.php/api/activity/getChangeInfoOfficialSignupTutor', params);
// 获取活动督导申请列表
export const getOfficialActivityTutorApply = (params = {}) => service.get('/gzh/backend/public/index.php/api/activity/getOfficialActivityTutorApply', params);

// 发送邀请申请督导
export const postOfficialActivityTutorApply = (params = {}) => service.post('/gzh/backend/public/index.php/api/activity/postOfficialActivityTutorApply', params);
// 接受申请督导的邀请
export const postOfficialActivityTutorApplyAccept = (params = {}) => service.post('/gzh/backend/public/index.php/api/activity/postOfficialActivityTutorApplyAccept', params);
// 主任导师接受and拒绝申请督导的邀请
export const postOfficialActivityTutorApplyCheck = (params = {}) => service.post('/gzh/backend/public/index.php/api/activity/postOfficialActivityTutorApplyCheck', params);
// 支付完成督导预约成功
export const postOfficialActivityTutorApplySuccess = (params = {}) => service.post('/gzh/backend/public/index.php/api/activity/postOfficialActivityTutorApplySuccess', params);
// 督导预约通知页面数据
export const getOfficialActivityTutorApplyDetail = (params = {}) => service.get('/gzh/backend/public/index.php/api/activity/getOfficialActivityTutorApplyDetail', params);
// 线上课堂激活码
export const postActivitySecurityCode = (params = {}) => service.get('/gzh/backend/public/index.php/api/user/postActivitySecurityCode', params);
// 线上课堂获取课程列表
export const getOnlineCourseList = (params = {}) => service.get('/gzh/backend/public/index.php/api/user/getOnlineCourseList', params);
// 分组的线上课堂课程列表
export const getOnlineCourseChapterListNew = (params = {}) => service.get('/gzh/backend/public/index.php/api/user/getOnlineCourseChapterListNew', params);

// 获取在线课程的试题列表
export const getOnlineCourseQuestionsList = (params = {}) => service.get('/gzh/backend/public/index.php/api/user/getOnlineCourseQuestionsList', params);
// 提交考试成绩
export const postOnlineCourseRecord = (params = {}) => service.get('/gzh/backend/public/index.php/api/user/postOnlineCourseRecord', params);
// 获取用户钱包信息
export const getClientUsersAccount = (params = {}) => service.get('/gzh/backend/public/index.php/api/user/getClientUsersAccount', params);
// 零钱提现
export const usersWithdraw = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/usersWithdraw', params);
// 充值接口
export const userRechargeUniOrder = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/userRechargeUniOrder', params);
// 设置密码
export const postClientUsersAccountPaymentcode = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/postClientUsersAccountPaymentcode', params);
// 获取津贴发放列表
export const getClientUsersAccountLogList = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/getClientUsersAccountLogList', params);
// 获取问答列表
export const getQuestionAskList = (params = {}) => service.get('/gzh/backend/public/index.php/api/question/getQuestionAskList', params);
// 获取问答详情
export const getQuestionAskDetail = (params = {}) => service.get('/gzh/backend/public/index.php/api/question/getQuestionAskDetail', params);
// 用户发表提问
export const postQuestionAsk = (params = {}) => service.post('/gzh/backend/public/index.php/api/question/postQuestionAsk', params);
// 我的提问列表
export const getUserQuestionAskList = (params = {}) => service.get('/gzh/backend/public/index.php/api/question/getUserQuestionAskList', params);
// 我的提问设为已读/全部已读
export const postUnReadQuestionAskReply = (params = {}) => service.get('/gzh/backend/public/index.php/api/question/postUnReadQuestionAskReply', params);
// 回答点赞
export const postQuestionReplySupport = (params = {}) => service.get('/gzh/backend/public/index.php/api/question/postQuestionReplySupport', params);
// 发表回复
export const postQuestionReply = (params = {}) => service.post('/gzh/backend/public/index.php/api/question/postQuestionReply', params);
// 发表回复的评论
export const postAuestionReplyComment = (params = {}) => service.post('/gzh/backend/public/index.php/api/question/postAuestionReplyComment', params);
// 急救热文文章列表
export const getWeixinAidHotArticleList = (params = {}) => service.get('/gzh/backend/public/index.php/api/article/getWeixinAidHotArticleList', params);
// 发布分享
export const postWeixinAidHotArticleShare = (params = {}) => service.post('/gzh/backend/public/index.php/api/article/postWeixinAidHotArticleShare', params);
// 精选文章列表
export const getWeixinAidHotArticleEssenceList = (params = {}) => service.post('/gzh/backend/public/index.php/api/article/getWeixinAidHotArticleEssenceList', params);
// 发布分享
export const postWeixinAidHotArticle = (params = {}) => service.post('/gzh/backend/public/index.php/api/article/postWeixinAidHotArticle', params);
// 急救热文文章页详情
export const getWeixinAidHotArticleDetail = (params = {}) => service.post('/gzh/backend/public/index.php/api/article/getWeixinAidHotArticleDetail', params);
// 查看回复
export const getWeixinAidHotArticleCommentReplyList = (params = {}) => service.post('/gzh/backend/public/index.php/api/article/getWeixinAidHotArticleCommentReplyList', params);
// 发表文章评论
export const postWeixinAidHotArticleComment = (params = {}) => service.post('/gzh/backend/public/index.php/api/article/postWeixinAidHotArticleComment', params);
// 文章评论排序
export const getWeixinAidHotArticleCommentOrder = (params = {}) => service.post('/gzh/backend/public/index.php/api/article/getWeixinAidHotArticleCommentOrder', params);
// 文章点赞
export const postWeixinAidHotArticlePraise = (params = {}) => service.post('/gzh/backend/public/index.php/api/article/postWeixinAidHotArticlePraise', params);
// 评论点赞
export const postWeixinAidHotArticleCommentPraise = (params = {}) => service.post('/gzh/backend/public/index.php/api/article/postWeixinAidHotArticleCommentPraise', params);
// 待处理消息列表
export const getWeixinAidHotArticleUserInformationUndeal = (params = {}) => service.post('/gzh/backend/public/index.php/api/article/getWeixinAidHotArticleUserInformationUndeal', params);
// 我的发布文章列表
export const getUserWeixinAidHotArticleList = (params = {}) => service.post('/gzh/backend/public/index.php/api/article/getUserWeixinAidHotArticleList', params);
// 热文用户收藏
export const postWeixinAidHotArticleUserCollectAdd = (params = {}) => service.post('/gzh/backend/public/index.php/api/article/postWeixinAidHotArticleUserCollectAdd', params);
// 我的收藏列表
export const getWeixinAidHotArticleUserCollectList = (params = {}) => service.post('/gzh/backend/public/index.php/api/article/getWeixinAidHotArticleUserCollectList', params);
// 文章删除
export const postWeixinAidHotArticleDel = (params = {}) => service.post('/gzh/backend/public/index.php/api/article/postWeixinAidHotArticleDel', params);
// 待处理消息回复
export const postWeixinAidHotArticleUserInformationReply = (params = {}) => service.post('/gzh/backend/public/index.php/api/article/postWeixinAidHotArticleUserInformationReply', params);
// 文章回复的回复
export const postWeixinAidHotArticleUserReply = (params = {}) => service.post('/gzh/backend/public/index.php/api/article/postWeixinAidHotArticleUserReply', params);
// 编辑分享
export const postWeixinAidHotArticleShareEdit = (params = {}) => service.post('/gzh/backend/public/index.php/api/article/postWeixinAidHotArticleShareEdit', params);
// 编辑文章
export const postWeixinAidHotArticleEdit = (params = {}) => service.post('/gzh/backend/public/index.php/api/article/postWeixinAidHotArticleEdit', params);

// 获取视频列表
export const getVideoList = (params = {}) => service.get('/gzh/backend/public/index.php/api/Video/getVideoList', params);
export const JjgetVideoList = (params = {}) => service.get('/gzh/backend/public/index.php/api/Jjvideo/getVideoList', params);
// 获取视频详情
export const getVideoDetail = (params = {}) => service.get('/gzh/backend/public/index.php/api/Video/getVideoDetail', params);
// 获取视频详情
export const JjgetVideoDetail = (params = {}) => service.get('/gzh/backend/public/index.php/api/Jjvideo/getVideoDetail', params);
// 已发布列表
export const getUserVideoList = (params = {}) => service.get('/gzh/backend/public/index.php/api/Video/getUserVideoList', params);
export const JjgetUserVideoList = (params = {}) => service.get('/gzh/backend/public/index.php/api/Jjvideo/getUserVideoList', params);
// 视频回复
export const postVideoReply = (params = {}) => service.post('/gzh/backend/public/index.php/api/Video/postVideoReply', params);
// 视频编辑
export const postVideoEdit = (params = {}) => service.post('/gzh/backend/public/index.php/api/Video/postVideoEdit', params);
// 视频编辑
export const JjpostVideoEdit = (params = {}) => service.post('/gzh/backend/public/index.php/api/Jjvideo/postVideoEdit', params);
// 视频详情评论排序
export const getVideoCommentDetail = (params = {}) => service.post('/gzh/backend/public/index.php/api/Video/getVideoCommentDetail', params);
// 视频回复的评论
export const postVideoReplyComment = (params = {}) => service.post('/gzh/backend/public/index.php/api/Video/postVideoReplyComment', params);

// 视频点赞接口
export const postVideoPraise = (params = {}) => service.post('/gzh/backend/public/index.php/api/Video/postVideoPraise', params);
// 视频点赞接口
export const JjpostVideoPraise = (params = {}) => service.post('/gzh/backend/public/index.php/api/Jjvideo/postVideoPraise', params);
// 视频回复点赞接口
export const postVideoReplyPraise = (params = {}) => service.post('/gzh/backend/public/index.php/api/Video/postVideoReplyPraise', params);
// 学员报名详情
export const signUpDetail = (params = {}) => service.post('/gzh/backend/public/index.php/api/activity/signUpDetail', params);
// 修改登录密码
export const postChangeClientUserscode = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/postChangeClientUserscode', params);
// 获取JSSDK wx.config 配置
export const getSign = (params = {}) => service.post('/gzh/backend/public/index.php/api/Share/getSign', params);
// 分享书籍页面提交订单
export const shareOrder = (params = {}) => service.post('/gzh/backend/public/index.php/api/Share/shareOrder', params);
// 知识更新列表
export const getKnowledge = (params = {}) => service.post('/gzh/backend/public/index.php/api/Share/getKnowledge', params);
// 获取知识更新详情
export const getKnowledgeDetail = (params = {}) => service.post('/gzh/backend/public/index.php/api/Share/getKnowledgeDetail', params);
// 修改支付密码
export const postChangeClientUsersAccountPaymentcode = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/postChangeClientUsersAccountPaymentcode', params);
// 获取书本价格邮费
export const getBookMoney = (params = {}) => service.post('/gzh/backend/public/index.php/api/Share/getBookMoney', params);
// 获取演练视频分类列表
export const getVideoTypeList = (params = {}) => service.post('/gzh/backend/public/index.php/api/Video/getVideoTypeList', params);
// 视频增加播放数
export const postVideoViewNum = (params = {}) => service.post('/gzh/backend/public/index.php/api/Video/postVideoViewNum', params);
// 知识更新标记为已读
export const knowledgeIsView = (params = {}) => service.post('/gzh/backend/public/index.php/api/Share/knowledgeIsView', params);
// 获取讲师分享成功购买的统计
export const getIntroduceCount = (params = {}) => service.post('/gzh/backend/public/index.php/api/Share/getIntroduceCount', params);
// 获取卖家最后一次提交的收获地址
export const getLastAddress = (params = {}) => service.post('/gzh/backend/public/index.php/api/Share/getLastAddress', params);
// 钱包使用记录
export const getClientUsersAccountRecord = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/getClientUsersAccountRecord', params);
// 线上课堂篇章列表
export const getOnlineCourseChapterList = (params = {}) => service.get('/gzh/backend/public/index.php/api/user/getOnlineCourseChapterList', params);
// 线上课堂章节视频列表
export const getOnlineCourseChapterById = (params = {}) => service.get('/gzh/backend/public/index.php/api/user/getOnlineCourseChapterById', params);
// 提交在线课堂视频学习进度
export const postOnlineCourseLearningrate = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/postOnlineCourseLearningrate', params);
// 获取评价提交页面
export const getActivityCommentSubjectList = (params = {}) => service.get('/gzh/backend/public/index.php/api/user/getActivityCommentSubjectList', params);
// 提交视频封面图片
export const postVideoImgAndReturnUrl = (params = {}) => service.post('/gzh/backend/public/index.php/api/video/postVideoImgAndReturnUrl', params);
// 保存培训图片
export const postSceneActivityImage = (params = {}) => service.post('/gzh/backend/public/index.php/api/Activity/postSceneActivityImage', params);
/*----------------------1.3.2------------------------------------*/
// 提交个人证书信息
export const postUserCertsInfo = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/postUserCertsInfo', params);
// 个人证书信息
export const getUserCertsInfo = (params = {}) => service.get('/gzh/backend/public/index.php/api/user/getUserCertsInfo', params);
// 在线课堂证书合成
export const postOnlineCourseCert = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/postOnlineCourseCert', params);
// 获得合格证状态
export const getActivityCanSignup = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/getActivityCanSignupNew', params);
// 查询快递
export const getDeliveryByOrderid = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/getDeliveryByOrderid', params);
// 考核题目
export const getOnlineCourseExamineTitle = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/getOnlineCourseExamineTitle', params);

export const getCourseExamineTitleCourseInfo = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/getCourseExamineTitleCourseInfo', params);
export const getCourseExamineTitle = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/getCourseExamineTitle', params);
// 查看成绩
export const getCourseExamineCourseAnswer = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/getCourseExamineCourseAnswer', params);
// 提交
export const getOtherUserCertsInfo = (params = {}) => service.get('/gzh/backend/public/index.php/api/user/getOtherUserCertsInfo', params);
// 查看学员证书信息
export const postOnlineCourseExamineResult = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/postOnlineCourseExamineResult', params);

export const getOnlineCourseCertSendMessage = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/getOnlineCourseCertSendMessage', params);
// 发送证书信息缺少通知

export const postCourseExamineResult = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/postCourseExamineResult', params);

// 判断是否可以发证
export const getOnlineCourseRecordCanCert = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/getOnlineCourseRecordCanCertNew', params);

// 培训证书生成
export const postOnlineCourseCertOffLine = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/postOnlineCourseCertOffLine', params);

// 
export const getLoginResult = (params = {}) => service.post('/gzh/backend/public/index.php/api/Teachsystem/getLoginResult', params);
export const postTeacherSignin = (params = {}) => service.post('/gzh/backend/public/index.php/api/activity/postTeacherSignin', params);

// 可申请加入的机构列表
export const institutionList = (params = {}) => service.get('/gzh/backend/public/index.php/api/other/institutionList', params);
// 我已加入的机构列表
export const insUserAdd = (params = {}) => service.get('/gzh/backend/public/index.php/api/other/insUserAdd', params);
// 申请加入机构（申请提交）
export const institutionApply = (params = {}) => service.post('/gzh/backend/public/index.php/api/other/institutionApply', params);
// 退出已加入的机构
export const institutionOut = (params = {}) => service.post('/gzh/backend/public/index.php/api/other/institutionOut', params);

// 督导提交导师考核接口
export const postCourseExamineResultTutor = (params = {}) => service.post('/gzh/backend/public/index.php/api/user/postCourseExamineResultTutor', params);
export const getExampleVideo = (params = {}) => service.post('/gzh/backend/public/index.php/api/other/getExampleVideo', params);
// 助教报名搜索
export const searchTeacher = (params = {}) => service.post('/gzh/backend/public/index.php/api/other/searchTeacher', params);
export const postSignupSearchTutor = (params = {}) => service.post('/gzh/backend/public/index.php/api/activity/postSignupSearchTutor', params);
// 主任导师搜索
export const searchTutor = (params = {}) => service.post('/gzh/backend/public/index.php/api/Other/searchTutor', params);

// 培训机构接口
export const getInstitutionList = (params = {}) => service.get('/gzh/backend/public/index.php/api/actmember/getInstitutionList', params);
export const getInsDetail = (params = {}) => service.get('/gzh/backend/public/index.php/api/actmember/getInsDetail', params);
export const insTrainInvited = (params = {}) => service.post('/gzh/backend/public/index.php/api/Actmember/insTrainInvited', params);
export const userInsConsultant = (params = {}) => service.post('/gzh/backend/public/index.php/api/Actmember/userInsConsultant', params);
export const getMyConsultant = (params = {}) => service.get('/gzh/backend/public/index.php/api/actmember/getMyConsultant', params);
export const consultantDetail = (params = {}) => service.post('/gzh/backend/public/index.php/api/actmember/consultantDetail', params);
export const continueConsultant = (params = {}) => service.post('/gzh/backend/public/index.php/api/actmember/continueConsultant', params);
export const cooperateSign = (params = {}) => service.post('/gzh/backend/public/index.php/api/actmember/cooperateSign', params);

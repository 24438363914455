import axios from 'axios';
import qs from 'qs';
import {login} from '@/util/login.js'
// import { getToken } from '@/util/auth';

const service = axios.create({
    baseURL: process.env.BASE_API || '', // api的base_url
    timeout: 200000 // 20s
});
//发起授权
	function opentoauth(url, state) {
		var new_url = url
		var new_state = state
		window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxbd8ba94475881480&redirect_uri=' +
			new_url + '&response_type=code&scope=snsapi_userinfo&state=' + new_state + '#wechat_redirect';
	}

const successCode = 200;

const request = (method, url, data, config) => {
    if (!config) {
        config = data ? Object.assign({}, data) : {};
        data = {};
    }
    const options = Object.assign({}, config, {
        method,
        url,
        data
    });
    return new Promise((resolve, reject) => {
        service.request(options)
            .then(res => {
                let {
                    data,
                    status
                } = res;
                if (status === successCode) {
                    resolve(data, res)
                } else {
                    reject(res);
                }
            }).catch(reason => {
                reject(reason);
            });
    });
};

export default {
    get(url, params, config = {}) {
        return request('get', url, { ...config,
            params
        });
    },
    delete(url, config) {
        return request('delete', url, config);
    },
    head(url, config) {
        return request('head', url, config);
    },
    post(url, data, config = {}) {
        return request('post', url, qs.stringify(data), config);
    },
    put(url, data, config = {}) {
        return request('put', url, data, config);
    },
    patch(url, data, config) {
        return request('patch', url, qs.stringify(data), config);
    },
};
let url = window.location.href
service.interceptors.request.use(config => {
		
        const token = sessionStorage.getItem('token')
        if (token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
            if (config.method === 'post') {
                config.data = `${config.data}&token=${token}`;
            } else { // get
                config.params = config.params || {};
                config.params['token'] = token;
            }
        }
        return config;
    },
    err => {
        return Promise.reject(err);
    });
service.interceptors.response.use(
  response => {
    //拦截响应，做统一处理 
    if (response.data.code) {
      switch (response.data.code) {
        case 401:
           // localStorage.removeItem('token')
		   // window.location.href = 'https://xcjj.tysos.cn/gzh/backend/wxauth.html?url=' + url
          
      }
    }
    return response
  },
  //接口错误状态处理，也就是说无响应时的处理
  error => {
		if(error.response.status==401){
			console.log('401')
			sessionStorage.removeItem('token')
			// var state = Date.parse(new Date()); //毫秒
			// var url = '
			// opentoauth(url, state)
		  window.location.href = 'https://xcjj.tysos.cn/gzh/backend/wxauth.html?url=' + url
		}
	  // console.log(error.response.status)
    return Promise.reject(error.response.status) // 返回接口返回的错误信息
  })
 